import Vue from 'vue'
import App from './App'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

// Use Google Analytics plugin for Vue
Vue.use(VueGtag, {
  config: {
    id: 'G-WTVV31TGBN',
    params: {
      send_page_view: true,
    },
  },
})

Vue.use(VueAxios, axios.create({ baseURL: '/api' }))
Vue.use(VueRouter)
Vue.use(Vuex)

let vRouter = new VueRouter(router)
vRouter.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.user) {
      next({ name: 'login' })
      return
    }
    next() // go to wherever I'm going
    return
  }
  next() // does not require auth, make sure to always call next()!
})

new Vue({
  vuetify,
  router: vRouter,
  store: new Vuex.Store(store),
  render: (h) => h(App),
}).$mount('#app')
