// all search api methods are defined here
import Vue from 'vue'

// exported api methods
export default {
  getSearchResults(searchRequest) {
    return Vue.axios.post('/search/v1/search.php', JSON.stringify(searchRequest))
  },
  getRedditSearchResults(searchRequest) {
    return Vue.axios.post('/search/v1/reddit.php', JSON.stringify(searchRequest))
  },
  getAISearchResults(searchRequest) {
    return Vue.axios.post('/search/v1/businessideas.php', JSON.stringify(searchRequest))
  },
  getYelpSearchResults(searchRequest) {
    return Vue.axios.post('/search/v1/yelp.php', JSON.stringify(searchRequest))
  },
  getYelpLocations(str) {
    return Vue.axios.get('/search/v1/yelpLocations.php?search=' + encodeURIComponent(str))
  },
  checkAccountHasSearches() {
    return Vue.axios.get('/search/v1/accountHasSearches.php')
  },
  getSearchResultsCSV(formData) {
    return Vue.axios.post('/search/v1/secretSauceSearch.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
