<template>
  <div>
    <template v-if="shouldDisplayHeaderStyleSearchBar && !isDesktop">
      <!-- search bar for mobile on the dashboard page -->
      <!-- <v-row class="mt-12" align-self="center" style="width: 100%"> -->
      <v-row align-self="center" style="width: 100%; margin-bottom: 28px">
        <v-col class="pt-2" cols="10">
          <v-text-field
            v-model="searchTerm"
            @input="updateSearchTerm"
            hide-details
            prepend-inner-icon="mdi-magnify"
            @keydown="handleKeyDown"
          ></v-text-field>
        </v-col>
        <v-col style="text-align: right" class="pr-0" cols="2">
          <v-btn
            @click="handleSearch"
            fab
            elevation="0"
            :loading="isLoadingSearchResults"
            color="#422ba4"
            class="white--text"
            width="36px"
            height="36px"
            style="margin-top: -8px"
            ><v-icon>mdi-arrow-top-right</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <!-- TODO - commenting out the filter options for now, we will add them back in when ai generated business ideas are available -->
      <!-- <v-row class="filtersSection">
        <v-row>
          <v-col class="py-0 pl-4">
            <b>{{ searchTerm }}</b>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="filterColumn">
            <v-select
              class="filterDropDown"
              hide-details
              :items="researchStageOptions"
              v-model="researchStageSelection"
              label="Research Stage"
              filled
              rounded
            ></v-select>
          </v-col>
          <v-col class="filterColumn">
            <v-select
              class="filterDropDown"
              hide-details
              :items="businessTypeOptions"
              v-model="businessTypeSelections"
              multiple
              label="Business Type (up to 3)"
              filled
              rounded
              @input="limiter($event, 3)"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col class="filterColumn">
            <v-select
              class="filterDropDown"
              hide-details
              :items="goalOptions"
              v-model="goalSelections"
              multiple
              label="Goal (up to 2)"
              filled
              rounded
              @input="limiter($event, 2)"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-row> -->
    </template>
    <template v-else-if="isHeader && !isIntroPage">
      <!-- if search bar is used as header, and this is not the intro page, do a single row (unless mobile) -->
      <v-row align-self="center" style="width: 100%">
        <!-- <v-col :cols="shouldDisplayFilters ? '' : '8'" align="center" align-self="center" class="pt-2"> -->
        <v-col :cols="shouldDisplayFilters ? '4' : '8'" align="center" align-self="center" class="pt-2">
          <!-- don't bother showing the search bar and search button on the search-steps page -->
          <v-text-field
            v-if="shouldDisplayFilters"
            v-model="searchTerm"
            @input="updateSearchTerm"
            :solo="!shouldDisplayFilters"
            :rounded="!shouldDisplayFilters"
            prepend-inner-icon="mdi-magnify"
            @keydown="handleKeyDown"
            :hide-details="shouldHideDetails"
            :rules="[validationRules.maxWords]"
          ></v-text-field>
        </v-col>
        <!-- <v-col :cols="shouldDisplayFilters ? '' : '4'" align="center" align-self="center"> -->
        <v-col cols="4" align="center" align-self="center">
          <v-btn
            v-if="shouldDisplayFilters"
            @click="handleSearch"
            color="#422ba4"
            class="white--text"
            rounded
            :x-large="isDesktop"
            :large="!isDesktop"
            :disabled="!searchTerm"
            :loading="isLoadingSearchResults"
            >Search</v-btn
          >
        </v-col>
        <!-- TODO - commenting out the filter options for now, we will add them back in when ai generated business ideas are available -->
        <!-- <template v-if="shouldDisplayFilters">
          <v-col class="filterColumn">
            <v-select
              class="filterDropDown"
              hide-details
              :items="researchStageOptions"
              v-model="researchStageSelection"
              label="Research Stage"
              filled
              rounded
            ></v-select>
          </v-col>
          <v-col class="filterColumn">
            <v-select
              class="filterDropDown"
              hide-details
              :items="businessTypeOptions"
              v-model="businessTypeSelections"
              multiple
              label="Business Type (up to 3)"
              filled
              rounded
              @input="limiter($event, 3)"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col class="filterColumn">
            <v-select
              class="filterDropDown"
              hide-details
              :items="goalOptions"
              v-model="goalSelections"
              multiple
              label="Goal (up to 2)"
              filled
              rounded
              @input="limiter($event, 2)"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item }}</span>
              </template>
            </v-select>
          </v-col>
        </template> -->
      </v-row>
    </template>
    <template v-else-if="!isHeader">
      <!-- basically just used for the intro page -->
      <v-row>
        <v-col align="center">
          <v-text-field
            v-model="searchTerm"
            @input="updateSearchTerm"
            solo
            rounded
            prepend-inner-icon="mdi-magnify"
            @keydown="handleKeyDown"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mainSearchButtonRow">
        <v-col align="center">
          <v-btn
            @click="handleSearch"
            :loading="isLoadingSearchResults"
            :disabled="!searchTerm"
            color="#422ba4"
            class="white--text"
            rounded
            x-large
            >Search</v-btn
          >
        </v-col>
      </v-row>
    </template>
    <EmailModal :isOpen="isEmailModalOpen" @submitEmail="submitEmail" />
  </div>
</template>

<script>
import searchAPI from '../api/search'
import EmailModal from './EmailModal'
export default {
  name: 'SearchBarComponent',
  props: {
    isHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EmailModal,
  },
  data() {
    return {
      gotItClicked: false,
      researchStageSelection: undefined,
      researchStageOptions: [
        "I'm looking for business ideas",
        'I have an idea',
        "I've already started my business",
        'Just curious about this tool',
      ],
      businessTypeSelections: [],
      businessTypeOptions: [
        'Ecommerce',
        'Mobile Application',
        'Traditional Retail',
        'SAAS',
        'B2B',
        'Service-Based',
        'Product',
        'Not Sure',
        'Surprise Me',
      ],
      keywordSelections: [],
      goalSelections: [],
      goalOptions: [
        'I want a passive income source',
        'I want to build something great',
        'I want to change an industry',
        'I want to change the world',
        'No goal, just fun',
      ],
      accountHasSearches: false,
      isEmailModalOpen: false,
      validationRules: {
        maxWords: (value) => {
          if (!value) return true
          return value.split(' ').length < 5 || 'Woah there! Remember to keep your searches simple. 2-3 words is best.'
        },
      },
    }
  },
  beforeMount() {
    if (this.shouldDisplayFilters) {
      const searchConfig = this.$store.getters.searchConfig
      this.researchStageSelection = searchConfig?.researchStageSelection || ''
      this.businessTypeSelections = searchConfig?.businessTypeSelections || []
      this.goalSelections = searchConfig?.goalSelections || []
    }
  },
  computed: {
    shouldHideDetails() {
      return this.searchTerm.split(' ').length < 5
    },
    isIntroPage() {
      return this.$store.getters.currentPath == '/'
    },
    shouldDisplayFilters() {
      return !this.isIntroPage && this.$store.getters.currentPath != '/search-steps'
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    shouldDisplayHeaderStyleSearchBar() {
      return (
        this.$store.getters.currentPath == '/dashboard' ||
        this.$store.getters.currentPath == '/search-results' ||
        this.$store.getters.currentPath == '/reddit-results' ||
        this.$store.getters.currentPath == '/yelp-results'
      )
    },
    isLoadingSearchResults() {
      return this.$store.getters.isLoadingSearchResults
    },
    selectedYelpLocation() {
      // gets the selected Yelp location or an empty string if not selected yet
      return this.$store.getters.selectedYelpLocation
    },
    searchTerm: {
      get() {
        return this.$store.getters.currentSearchTerm || ''
      },
      set(value) {
        this.updateSearchTerm(value)
      },
    },
  },
  methods: {
    updateSearchTerm(value) {
      this.$store.commit('setCurrentSearchTerm', value)
    },
    async handleSearch() {
      this.$store.commit('setIsLoadingSearchResults', true)
      if (this.isIntroPage) {
        // TODO - commenting this out for now, but once we have actual use for the steps, we will put this back in
        // await this.checkAccountHasSearches()
        // if (!this.accountHasSearches) {
        //   this.$router.push('/search-steps')
        //   return
        // }
        await this.getResults()
        if (this.isEmailModalOpen) {
          return
        }
        this.$router.push('/dashboard')
        return
      }
      this.getResults()
    },
    async checkAccountHasSearches() {
      await searchAPI.checkAccountHasSearches().then((response) => {
        this.accountHasSearches = response.data > 0
      })
    },
    limiter(e, max) {
      if (e.length > max) {
        e.shift()
      }
    },
    handleKeyDown(e) {
      // if the user presses enter (and isn't holding shift or control), search
      if (e.key == 'Enter' && !e.shiftKey && !e.ctrlKey) {
        e.preventDefault()
        this.handleSearch()
      }
    },
    async submitEmail(emailAddress) {
      await this.getResults(emailAddress)
      this.isEmailModalOpen = false
      if (this.isIntroPage) {
        this.$router.push('/dashboard')
      }
    },
    async getResults(emailAddress = undefined) {
      this.$store.commit('setIsLoadingSearchResults', true)
      var searchConfig = {
        keywordSelections: this.searchTerm.split(' '),
        researchStageSelection: this.researchStageOptions[this.researchStageSelection],
        businessTypeSelections: this.businessTypeSelections.map((option) => this.businessTypeOptions[option]),
        goalSelections: this.goalSelections.map((option) => this.goalOptions[option]),
        location: this.selectedYelpLocation || 'Canada',
      }
      this.$store.commit('setSearchConfig', searchConfig)
      let searchResultsPromise = searchAPI
        .getSearchResults({
          keyword: searchConfig.keywordSelections.join(' '),
          emailAddress,
        })
        .then((response) => {
          this.$store.commit('setSearchResults', response.data.results)
          this.$store.commit('setSearchHistory', [searchConfig.keywordSelections.join(' ')])
          if (!response.data.results.length && response.data.suggestions.length) {
            this.$store.commit('setShowSuggestions', true)
            this.$store.commit('setAISuggestions', response.data.suggestions)
          } else {
            this.$store.commit('setShowSuggestions', false)
            this.$store.commit('setAISuggestions', [])
          }
          this.$store.commit('setIsLoadingSearchResults', false)
        })
        .catch((err) => {
          if (err?.response?.status == 429) {
            // ask for email
            this.isEmailModalOpen = true
            this.$store.commit('setIsLoadingSearchResults', false)
            return
          }
          this.setDefaultErrorMessage()
          this.$store.commit('setIsLoadingSearchResults', false)
        })
      this.$store.dispatch('getRedditResults', searchConfig)
      this.$store.dispatch('getBusinessIdeas', searchConfig)
      // only try to get yelp results if the location is already set
      if (this.selectedYelpLocation) {
        this.$store.dispatch('getYelpResults', searchConfig)
      }
      return searchResultsPromise
    },
    setDefaultErrorMessage() {
      this.$store.commit('setSnackbar', {
        type: 'error',
        message: `Sorry, unable to retrieve search results. Please try again later.`,
      })
    },
  },
}
</script>

<style>
.gotIt {
  cursor: pointer;
}
.round {
  border-radius: 50px;
}
.mainSearchButtonRow {
  display: flex;
  justify-content: center;
  margin-top: -24px !important;
}
.filterColumn {
  display: flex;
  align-items: center;
}
.filterDropDown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 335px;
  max-width: 335px;
}
@media screen and (max-width: 501px) {
  .filterDropDown {
    width: 90px;
    font-size: 10px;
  }
  .filterDropDown .v-input__control {
    height: 50px;
  }
  .v-text-field--rounded > .v-input__control > .v-input__slot {
    padding: 0 10px;
  }
  .v-select__selections {
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .v-select__selections span {
    position: absolute;
    width: 58px;
    height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .v-select__selections input {
    padding: 0;
  }
  .v-select__selection--comma {
    margin: 0;
  }
  .filtersSection {
    background-color: white;
    margin: 12px -20px -12px -20px;
    padding: 16px;
    box-shadow: 0px 6px 5px 0px #00000020;
  }
}
</style>
