<template>
  <v-dialog v-model="modalIsOpen" persistent max-width="500">
    <v-card rounded="xl">
      <v-card-title class="text-h5 centered-title"> You might be onto something... </v-card-title>
      <v-card-text
        >To keep using the tools, enter your email address. We won't spam you, but we might ask what you thought of it,
        and what you think would be useful to add. We're a tiny little team, and would love your input.</v-card-text
      >
      <v-card-text>
        <v-text-field class="centered-input" placeholder="email address" v-model="emailAddress" dense></v-text-field>
      </v-card-text>
      <v-card-actions class="centered-actions">
        <v-tooltip :disabled="emailAddressIsValid" top>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                :disabled="!emailAddressIsValid"
                :loading="isLoading"
                x-large
                color="#422ba4"
                class="white--text"
                rounded
                @click="validateAndSubmit"
              >
                Continue
              </v-btn>
            </div>
          </template>
          <span>Please enter a valid email address.</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalIsOpen: false,
      emailAddress: '',
      isLoading: false,
    }
  },
  computed: {
    emailAddressIsValid() {
      return this.vemail()
    },
  },
  watch: {
    isOpen(value) {
      this.modalIsOpen = value
    },
  },
  methods: {
    vemail() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(this.emailAddress)
    },
    validateAndSubmit() {
      if (!this.emailAddressIsValid) return
      this.isLoading = true
      this.$emit('submitEmail', this.emailAddress)
    },
  },
}
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
.centered-actions.v-card__actions {
  justify-content: center !important;
}
.centered-title {
  display: flex;
  justify-content: center;
}
</style>
