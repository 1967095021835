<template>
  <v-app>
    <v-row no-gutters>
      <div class="pb-0 mainContent">
        <v-snackbar
          v-model="$store.state.snackbarIsOpen"
          timeout="4000"
          fixed
          bottom
          :color="$store.state.snackbarColor"
          rounded="pill"
        >
          {{ $store.state.snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="$store.commit('closeSnackbar')"> Close </v-btn>
          </template>
        </v-snackbar>
        <Header v-if="shouldDisplayHeader" />
        <router-view />
      </div>
    </v-row>
  </v-app>
</template>

<script>
import Header from './components/Header'
export default {
  name: 'App',
  components: {
    Header,
  },
  computed: {
    shouldDisplayHeader() {
      return this.$route.path != '/secret-sauce'
    },
  },
}
</script>

<style>
* {
  --var-app-baby-blue: #edf1f6;
  --var-app-dark-blue: #422ba4;
  --var-app-orange: #de8831;
}
html {
  overflow-y: hidden;
}
.mainContent {
  width: 100%;
  font-family: 'Poppins', sans-serif;
}
@media (max-width: 959px) {
  html {
    overflow-y: unset;
  }
}
</style>
