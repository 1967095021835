import VuexPersist from 'vuex-persist'
import searchAPI from './api/search'

const vuexSessionStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.sessionStorage,
  reducer: (state) => ({
    currentPath: state.currentPath,
    searchHintGotIt: state.searchHintGotIt,
    currentSearchTerm: state.currentSearchTerm,
    searchConfig: state.searchConfig,
    searchResults: state.searchResults,
    redditResults: state.redditResults,
    businessIdeas: state.businessIdeas,
    yelpResults: state.yelpResults,
    selectedYelpLocation: state.selectedYelpLocation,
    searchHistory: state.searchHistory,
    showSuggestions: state.showSuggestions,
    aiSuggestions: state.aiSuggestions,
  }),
})

export default {
  plugins: [vuexSessionStorage.plugin],
  // state - all the "global variables" go here
  state: {
    currentPath: '',
    user: undefined,
    searchHintGotIt: false,
    currentSearchTerm: '',
    searchHistory: [],
    searchConfig: {},
    searchResults: [],
    redditResults: [],
    businessIdeas: '',
    yelpResults: {},
    selectedYelpLocation: '',
    suggestedYelpLocations: [],
    showSuggestions: false,
    aiSuggestions: false,
    snackbarIsOpen: false,
    isLoadingSearchResults: false,
    isLoadingRedditResults: false,
    isLoadingBusinessIdeaResults: false,
    isLoadingYelpResults: false,
    snackbarColor: 'success',
    snackbarText: '',
  },
  // getters - all the "global computed properties" go here
  getters: {
    user: (state) => state.user,
    currentPath: (state) => state.currentPath,
    searchHintGotIt: (state) => state.searchHintGotIt,
    currentSearchTerm: (state) => state.currentSearchTerm,
    searchConfig: (state) => state.searchConfig,
    searchResults: (state) => state.searchResults,
    searchHistory: (state) => state.searchHistory,
    redditResults: (state) => state.redditResults,
    businessIdeas: (state) => state.businessIdeas,
    yelpResults: (state) => state.yelpResults,
    selectedYelpLocation: (state) => state.selectedYelpLocation,
    suggestedYelpLocations: (state) => state.suggestedYelpLocations,
    showSuggestions: (state) => state.showSuggestions,
    aiSuggestions: (state) => state.aiSuggestions,
    isLoadingSearchResults: (state) => state.isLoadingSearchResults,
    isLoadingRedditResults: (state) => state.isLoadingRedditResults,
    isLoadingBusinessIdeaResults: (state) => state.isLoadingBusinessIdeaResults,
    isLoadingYelpResults: (state) => state.isLoadingYelpResults,
  },
  // mutations (called with store.commit) - all the "global functions" go here (must be synchronous)
  // note: when adding new props to an object, use the "Vue.set" function
  // (ie. Vue.set(state.coolObject', 'newField', 'hello')
  mutations: {
    setCurrentPath(state, currentPath) {
      state.currentPath = currentPath
    },
    setUser(state, user) {
      state.user = user
    },
    setSearchHintGotIt(state, searchHintGotIt) {
      state.searchHintGotIt = searchHintGotIt
    },
    setCurrentSearchTerm(state, currentSearchTerm) {
      state.currentSearchTerm = currentSearchTerm
    },
    setSearchConfig(state, searchConfig) {
      state.searchConfig = searchConfig
    },
    setSearchResults(state, searchResults) {
      state.searchResults = searchResults
    },
    setIsLoadingSearchResults(state, isLoadingSearchResults) {
      state.isLoadingSearchResults = isLoadingSearchResults
    },
    setRedditResults(state, redditResults) {
      state.redditResults = redditResults
    },
    setIsLoadingRedditResults(state, isLoadingRedditResults) {
      state.isLoadingRedditResults = isLoadingRedditResults
    },
    setBusinessIdeas(state, businessIdeas) {
      state.businessIdeas = businessIdeas
    },
    setIsLoadingBusinessIdeaResults(state, isLoadingBusinessIdeaResults) {
      state.isLoadingBusinessIdeaResults = isLoadingBusinessIdeaResults
    },
    setYelpResults(state, yelpResults) {
      state.yelpResults = yelpResults
    },
    setIsLoadingYelpResults(state, isLoadingYelpResults) {
      state.isLoadingYelpResults = isLoadingYelpResults
    },
    setYelpLocation(state, selectedYelpLocation) {
      state.selectedYelpLocation = selectedYelpLocation
    },
    setYelpLocationSuggestions(state, suggestedYelpLocations) {
      state.suggestedYelpLocations = suggestedYelpLocations
    },
    setShowSuggestions(state, showSuggestions) {
      state.showSuggestions = showSuggestions
    },
    setAISuggestions(state, aiSuggestions) {
      state.aiSuggestions = aiSuggestions
    },
    setSearchHistory(state, searchHistory) {
      state.searchHistory = searchHistory
    },
    setSnackbar(state, snackbarProps) {
      state.snackbarColor = snackbarProps.type
      state.snackbarText = snackbarProps.message
      state.snackbarIsOpen = true
    },
    closeSnackbar(state) {
      state.snackbarIsOpen = false
    },
  },
  // actions (called with store.dispatch) - all the "global async functions" go here
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    async getRedditResults({ commit }, searchConfig) {
      commit('setIsLoadingRedditResults', true)
      await searchAPI.getRedditSearchResults({ keyword: searchConfig.keywordSelections.join(' ') }).then((response) => {
        if (response.data.length) {
          commit('setRedditResults', response.data)
        }
      })
      commit('setIsLoadingRedditResults', false)
    },
    async getBusinessIdeas({ commit }, searchConfig) {
      commit('setIsLoadingBusinessIdeaResults', true)
      await searchAPI.getAISearchResults({ keyword: searchConfig.keywordSelections.join(' ') }).then((response) => {
        if (response.data.length) {
          commit('setBusinessIdeas', response.data)
        }
      })
      commit('setIsLoadingBusinessIdeaResults', false)
    },
    async getYelpResults({ commit }, searchConfig) {
      commit('setIsLoadingYelpResults', true)
      await searchAPI
        .getYelpSearchResults({ keyword: searchConfig.keywordSelections.join(' '), location: searchConfig.location })
        .then((response) => {
          if (response.data) {
            commit('setYelpResults', response.data)
          }
        })
      commit('setIsLoadingYelpResults', false)
    },
    async getYelpLocations({ commit }, str) {
      if (!str) return
      await searchAPI.getYelpLocations(str).then((response) => {
        const parsedData = JSON.parse(response.data)
        if (parsedData && parsedData.suggestions) {
          commit(
            'setYelpLocationSuggestions',
            parsedData.suggestions.map((suggestion) => suggestion.query)
          )
        }
      })
    },
    async getSearchResultsCSV(_nada, formData) {
      if (!formData) return
      return searchAPI.getSearchResultsCSV(formData)
    },
  },
}
