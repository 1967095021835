<template>
  <v-app-bar class="py-4" app fixed id="header" style="z-index: 999999" elevation="0">
    <v-row :justify="shouldDisplayHeaderStyle1 ? 'center' : 'space-between'" no-gutters>
      <v-col class="titleText" cols="3"> <span @click="handleLogoClick">AmIOntoSomething</span> </v-col>
      <v-col v-if="shouldDisplayHeaderStyle1" align-self="center">
        <SearchBar v-if="isDesktop" :isHeader="true" />
      </v-col>
      <v-col no-gutters align="right" align-self="center" cols="3">
        <!-- this is the menu button, currently not showing, but if we need it, add it back in -->
        <template v-if="false">
          <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
          <v-navigation-drawer v-model="drawer" absolute temporary height="100vh">
            <v-list nav dense>
              <v-list-item-group>
                <v-list-item>
                  <router-link class="headerMenuItem mr-6" to="/">Yeet</router-link>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </template>
      </v-col>
    </v-row>
    <template v-slot:extension v-if="!isIntroPage && (!shouldDisplayHeaderStyle1 || !isDesktop)">
      <v-card class="secondaryHeader" :style="isDesktop ? '' : 'height: 50px; margin-top: 26px;'">
        <v-container :style="isDesktop ? '' : 'height: inherit'">
          <v-row :class="isDesktop ? 'px-8' : 'px-2'" :style="isDesktop ? '' : 'height: inherit'">
            <v-col align-self="center" :style="isDesktop ? '' : 'height: inherit'">
              <SearchBar :isHeader="true" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
  </v-app-bar>
</template>

<script>
import SearchBar from '../components/SearchBar'
export default {
  components: {
    SearchBar,
  },
  name: 'HeaderComponent',
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
    shouldDisplayHeaderStyle1() {
      return this.$route.path == '/' || this.$route.path == '/search-steps'
    },
    isIntroPage() {
      return this.$route.path == '/'
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp
    },
  },
  methods: {
    handleLogoClick() {
      if (this.$route.path == '/' || this.$route.path == '/search-steps' || this.$route.path == '/dashboard') return
      this.$router.push('/dashboard')
    },
  },
}
</script>

<style>
#header {
  background-color: var(--var-app-baby-blue);
  padding-top: 0px !important;
}
.titleText {
  display: flex;
  align-items: center;
  color: #808080a8;
  font-size: 20px;
}
.titleText span {
  cursor: pointer;
}
a.headerMenuItem {
  text-decoration: none;
  font-size: 20px;
}
.clickable {
  cursor: pointer;
}
#loginSignUpLandingButton {
  background-color: var(--var-app-orange);
}
a.router-link-exact-active {
  color: var(--var-app-orange);
  font-weight: bold;
}
.v-toolbar__extension {
  padding: 0;
}
.secondaryHeader {
  width: 100%;
  margin-top: 70px;
  padding: 25px 0;
}
.secondaryHeader .container {
  width: 100%;
  max-width: 100%;
}
@media (max-width: 959px) {
  .v-toolbar__extension {
    height: 0px !important;
  }
  .secondaryHeader {
    padding: 0;
  }
  .secondaryHeader.theme--light.v-card {
    background-color: transparent;
  }
  .secondaryHeader.v-sheet.v-card {
    box-shadow: none;
  }
}
</style>
