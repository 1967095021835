export default {
  routes: [
    {
      path: '/',
      name: 'intro-screen',
      component: () => import('./views/IntroScreen.vue'),
    },
    {
      path: '/search-steps',
      name: 'search-steps',
      component: () => import('./views/SearchSteps.vue'),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('./views/DashboardScreen.vue'),
    },
    {
      path: '/search-results',
      name: 'search-results',
      component: () => import('./views/SearchResults.vue'),
    },
    {
      path: '/reddit-results',
      name: 'reddit-results',
      component: () => import('./views/RedditResults.vue'),
    },
    {
      path: '/yelp-results',
      name: 'yelp-results',
      component: () => import('./views/YelpResults.vue'),
    },
    {
      path: '/secret-sauce',
      name: 'secret-sauce',
      component: () => import('./views/SecretSauce.vue'),
    },
    // {
    //   path: '/:pathMatch(.*)*',
    //   name: 'NotFound',
    //   component: () => import('./views/LoginView.vue'),
    // },
  ],
  mode: 'history',
}
